import { useEffect } from "react"
declare global{ 
    interface Window {
        adsbygoogle: { [key: string]: unknown}[] 
    }
}

export const GoogleAds = () => {
    useEffect(() => {
        if( window.adsbygoogle && window.adsbygoogle.length > 0 ) {
            window.adsbygoogle.push({})
        }
    }, [])
    return (
        <footer className="google-ads">
            <ins
                className="adsbygoogle"
                style={{"display": "block"}}
                data-ad-client={ process.env.REACT_APP_GOOGLE_AD_CLIENT }
                data-ad-slot={ process.env.REACT_APP_GOOGLE_AD_SLOT }
                data-ad-format="auto"
                data-full-width-responsive="true"
            > </ins>
        </footer>
    )
}