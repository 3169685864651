import axios from "axios";
import { detect } from "detect-browser"
import { AUTOREMOVE_SAM_SWITCH, GET_SAM_SWITCH, REFRESH, SAM_SWITCH_MANANGE } from "../../../service/apiEndPoints";
import { METHOD, apiCall } from "../../../service/baseApiCall";
import { toast } from "react-toastify";
import { useEffect } from "react";

export const saveDataToLocalStorage = (key = "", user = {}) => {
  window.localStorage.setItem(key, JSON.stringify(user));
};

export const getDataFromLocalStorage = (key = "") => {
  let data = {};
  if (key) {
    data = JSON.parse(window.localStorage.getItem(key) || "{}");
  }
  return data;
};

export const removeDataFromLocalStorage = (key = "") => {
  if (key) {
    window.localStorage.removeItem(key);
  }
};

export const clearLocalStorage = () => {
  window.localStorage.clear();
};

export const validateToken = () => {
  const token = getDataFromLocalStorage("login-auth-token");
  const isLoggedIn = getDataFromLocalStorage("isLoggedIn");
  if (
    ["/", "/samwatch", "/samtheme", "/samstudio"].includes(
      window.location.pathname
    )
  ) {
    return false;
  };
  
  if( typeof(token) === "object" ) {
    if( Object.keys(token).length <= 0 ) {
      return false;
    };
  };

  if (token && isLoggedIn) {
    return true;
  };
  return false;
};

export const refreshToken = async (): Promise<Boolean> => {
  const reqPayload = {
    refresh_token: getDataFromLocalStorage("refresh-token"),
    email: getDataFromLocalStorage("email"),
  };
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}${REFRESH}`,
      reqPayload
    );
    saveDataToLocalStorage("login-auth-token", data?.data?.AccessToken);
    saveDataToLocalStorage("refresh-token", data?.data?.RefreshToken);
    return true;
  } catch (error) {
      console.log(error);
    return false;
  }
};

export const useConfirm = ( msg:string = "", onConfirm:any, onCancel:any) => {

  if (!onConfirm || typeof onConfirm !== "function") {
    return;
  }
  if (onCancel && typeof onCancel !== "function") {
    return;
  }

  const confirmAction = () => {
    if (window.confirm(msg)) {
      onConfirm();
    } else {
      onCancel();
    }
  };

  return confirmAction;
}

// 등록일 기준 36시간 이내 아이템 한정
export const newItemTag = ( item: any) => {
  let date = new Date()
  let parseDate = Date.parse(item)

  if( Math.abs(date.getTime() - parseDate) / 3600000 < 36 ) { return true } 
  return false
}

export const getBrowserInfo = () => { return detect() }



export const couponManage = ( coupon_enabled: boolean, type: string, sku: string, setState: any = null ) => {
  const URL = SAM_SWITCH_MANANGE.replace(":coupon_enabled", coupon_enabled.toString() ).replace(":sku", sku).replace(":type", type)
  if( setState !== null ) {
    setState( (prev:any) => { 
      if( type === "enable_get_coupon" ) {
        return { ...prev, [sku]: {  [type]: coupon_enabled, "enable_now_coupon": prev[sku].enable_now_coupon } } 
      } else if(type === "enable_free_coupon") {
        return { ...prev, [sku]: {  [type]: coupon_enabled, "enable_now_coupon": prev[sku].enable_now_coupon } } 
      } else {
        return { ...prev, [sku]: {  [type]: coupon_enabled, "enable_get_coupon": prev[sku].enable_get_coupon } } 
      }
    })
  }

  apiCall( URL, 
          {}, 
          (res: any) => {
            if( res.success ) {
              toast.success(`
                ${type === "enable_get_coupon" ? "Get" 
                  : type === "enable_now_coupon"? "Now" 
                  : "Free"} 타입을 변경하였습니다.`
              )
            }
          },
          (err: any) => {
              if (err === "unauthorized") {
                toast.error("토큰이 만료되었습니다. 다시 로그인 해주세요.")
              } else {
                toast.error("데이터베이스 에러가 발생했습니다. 로거를 확인해주십시오.")
              }
              console.log(err)
          },
          METHOD.PUT
  )
}

export const findMyCouponManageStatus = ( tempVal: any, type: string, elem: any ) => {
  let value = elem[type]
  for( const sku of Object.keys(tempVal) ) {
    if( sku === elem["sku"]){
      value = tempVal[sku]
    }
    break
  }

  return value
}

/** 
 * png 파일을 jpeg을 변환하는 코드 추가
 * 백엔드에서 따로 처리하려니 복잡하여 canvas api 사용
 * formik 데이터 타입이 조금 복잡하여 addEditWatch.tsx 코드를 보며 따라가야함
 */
export const png2jpg = async(image: File) => {
  return new Promise(( resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(image)

    reader.onload = (evt) => {
      let img = new Image()
      img.src = evt.target?.result as string

      img.onload = () => {
        const canvas = document.createElement("canvas")
        const ctx = canvas.getContext("2d")

        if(!ctx) {
          reject(new Error("Canvas context could not be initialized"))
          return
        }

        canvas.width = img.width
        canvas.height = img.height

        ctx?.drawImage(img, 0, 0)

        let jpegBase64 = canvas.toDataURL("image/jpeg", 0.8)

        const byteString = atob(jpegBase64.split(',')[1]);
        const mimeString = jpegBase64.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);

        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }

        const newBlob = new Blob([ab], { type: mimeString });
        const file = new File([newBlob], image.name.replace(".png", ".jpg"), {
          type: "image/jpeg",
        });

        const data_url = canvas.toDataURL("image/jpeg")

        resolve( {data_url, file} );
      }
      img.onerror = () => reject( new Error("failed to read image"))
    }
  })
}

export const checkBase64ImageExt = (image: string) => { 
  const mimeTypeMatch = image.match(/^data:(image\/[a-zA-Z]+);base64,/)

  if (mimeTypeMatch && mimeTypeMatch[1]) {
    const mimeType = mimeTypeMatch[1];
    
    switch (mimeType) {
      case "image/jpeg":
        return "jpg";
      case "image/png":
        return "png";
      case "image/gif":
        return "gif";
      case "image/webp":
        return "webp";
      default:
        return null; 
    }
  }

  return null; 
}

// 쿠폰 소진 시 자동삭제 여부 결정
// TODO 현재 사용되지 않음. 미래를 위해 삭제하지 않고 두기로 함
// export const autoRemoveHandler = ( auto_remove: boolean, sku: string, cb: any = null ) => { 
//   const URL = AUTOREMOVE_SAM_SWITCH.replace(":auto_remove", auto_remove.toString() ).replace(":sku", sku)
//   if( cb !== null ) {
//     cb?.set( (prev: any) => { return { ...prev, [sku]: auto_remove }} )
//   }
  
//   apiCall(URL,  
//           {},
//           (res:any) => {
//             let obj = auto_remove  ? "enable" : "disable"
//             toast.success(`auto_remove ${obj}`)
//           },
//           (err : any) => {
//             if( err === "token not found" || err === "unauthorized") {
//               toast.error("Unable to toggle button. token expired");
//             }
//             console.log("toggle failed", err)
//           },
//           METHOD.PUT
//   )
// }

// export const findAutoRemoveTmpVal = ( tempVal: any, elem: any) => {
  
//   let value = elem?.auto_remove 
//   for (const tmpSku of Object.keys(tempVal) ) {
//     if (tmpSku === elem?.sku) {
//       value = tempVal[tmpSku] 
//       break
//     }
//   }   
//   return value 
// }