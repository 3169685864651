import "./common.css"
import floatingMenuIcon from "../../../../assets/icons/FloatingMenu/floatingMenuIcon.png"
import watchGray from "../../../../assets/icons/FloatingMenu/watchGray.png"
import watchWhite from "../../../../assets/icons/FloatingMenu/watchWhite.png"
import watchBlack from "../../../../assets/icons/FloatingMenu/watchBlack.png"
import classicGray from "../../../../assets/icons/FloatingMenu/classicGray.png"
import classicBlack from "../../../../assets/icons/FloatingMenu/classicBlack.png"
import ultraWhite from "../../../../assets/icons/FloatingMenu/ultraWhite.png"
import ultraBlack from "../../../../assets/icons/FloatingMenu/ultraBlack.png"
import ultraOrange from "../../../../assets/icons/FloatingMenu/ultraOrange.png"

import { MENU_TYPE } from "../../utils/constants"

interface FloatingMenuSelector {
    type: string 
    isClick: boolean
    setIsClick: React.Dispatch<React.SetStateAction<boolean>> 
    setselectedWatchFrame: React.Dispatch<React.SetStateAction<string>>
}

export const FloatingWatchFrames = ( props: any ) => {
    let myImages = [ {raw: watchGray,   name: "watchGray"}, 
                     {raw: watchWhite,  name: "watchWhite"},
                     {raw: watchBlack,  name: "watchBlack"},
                     {raw: ultraWhite,  name: "ultraWhite"},
                     {raw: ultraOrange,  name: "ultraOrange"},
                     {raw: ultraBlack,  name: "ultraBlack"},
                     {raw: classicGray, name: "classicGray"}, 
                     {raw: classicBlack,name: "classicBlack"}
                   ]
    return (
        <div className="floating-menu-inside">
            {
                myImages.map( (image,idx) => (
                    <img 
                        key={idx}
                        onClick={() => {props.setselectedWatchFrame(image.name); props.handleClick(false)}}
                        src={image.raw}
                        alt=""
                    />
                ))
            }
        </div>
    )
}

export const FloatingMenu = ( props: FloatingMenuSelector) => {

    const colorMap = {
        [MENU_TYPE.SAM_WATCH]: { to: "#1e40af", from: "#002b71"},
        [MENU_TYPE.YAM_WATCH]: { to: "#fcc92c", from: "#e5005e"}
    }
    const handleClick = (type: boolean) => {
        props.setIsClick(type);
        return
    }

    return (
        <div 
            className={props.isClick ? "floating-menu-open" : "floating-menu"}
            style={{ background: `linear-gradient(to right, ${colorMap[props.type].to}, ${colorMap[props.type].from})`}}
        >
            { 
                props.isClick ?  
                <FloatingWatchFrames 
                    handleClick={handleClick} 
                    setselectedWatchFrame={props.setselectedWatchFrame}
                /> 
                :   
                <img
                    onClick={ () => { handleClick(true) } }
                    className="floating-menu-icon"
                    src={floatingMenuIcon}                    
                    alt=""
                />
            }
        </div>
    )
}