import "./App.css";
import Logo from "../../assets/icons/samtree-logo.png";
import SamWatch from "../../assets/icons/intro/pc/watch2_ok_n.png"
import iSamWatch from "../../assets/icons/intro/pc/watch2_ok_n_c.png";
import YamWatch from "../../assets/icons/intro/pc/yam.png"
import iYamWatch from "../../assets/icons/intro/pc/yam_c.png"
import SamTheme from "../../assets/icons/intro/pc/theme2_n.png"
import iSamTheme from "../../assets/icons/intro/pc/theme2_n_c.png"
import SamStudio from "../../assets/icons/intro/pc/studio2_n.png"
import iSamStudio from "../../assets/icons/intro/pc/studio2_n_c.png"
import SamWatchM from "../../assets/icons/intro/mobile/watch_m_ok_n.png"
import YamWatchM from "../../assets/icons/intro/mobile/yam2_ok_n.png"
import SamThemeM from "../../assets/icons/intro/mobile/theme2_ok_n.png"
import SamStudioM from "../../assets/icons/intro/mobile/studio2_ok_n.png"
import PageNotAvailable from "../common/Maintanance/notAvailablePage"

import { METHOD, apiCall } from "../../service/baseApiCall";
import { useNavigate } from "react-router-dom";
import { useRef, useState, useEffect } from "react";
import { getDataFromLocalStorage, removeDataFromLocalStorage, useConfirm } from "./utils/util"

export const App = () => {
  const [loader, setLoader]  = useState<boolean>(false)
  const [isNotAvailable, setIsNotAvailable] = useState<boolean>(false)
  const navigate = useNavigate(),
    [isLoggedIn, setIsLoggedIn] = useState(false),
    isLongPress = useRef<any>(),
    timerRef = useRef<any>();
  let count = 0;
  const clickCount = () => {
    count++;
    if (count === 10) navigate("/login");
  };
  const startPressTimer = () => {
    isLongPress.current = false;
    timerRef.current = setTimeout(() => {
      isLongPress.current = true;
    }, 10);
  };
  
  const logoutConfirm = () => {
    removeDataFromLocalStorage("login-auth-token");
    removeDataFromLocalStorage("isLoggedIn")
    removeDataFromLocalStorage("email")
    removeDataFromLocalStorage("refresh-token")

    window.location.reload()
  }
  const logoutCancel = () => { }
  let adminLogout = useConfirm( "로그아웃 하시겠습니까?", logoutConfirm, logoutCancel )

  const introImgType = [
    {leave: SamWatch,  over: iSamWatch, mobile: SamWatchM, navigate: "/samwatch"},
    {leave: YamWatch,  over: iYamWatch, mobile: YamWatchM, navigate: "/yamwatch"},
    {leave: SamTheme,  over: iSamTheme, mobile: SamThemeM, navigate: "/samtheme"},
    {leave: SamStudio, over: iSamStudio, mobile: SamStudioM, navigate: "/samstudio"},
  ]

  const healthCheck = () => {
    console.log( process.env.REACT_APP_AXIOS_TIMEOUT, process.env.REACT_APP_GOOGLE_AD_CLIENT, process.env.REACT_APP_GOOGLE_AD_SLOT)
    apiCall(
        "",
        {},
        (res: any) => {
            if (res.success === true) {
              setLoader(true)
            }
        },
        (err: any) => {
            setLoader(false)
            setIsNotAvailable(true)
        },
        METHOD.GET,
        {timeout: process.env.REACT_APP_AXIOS_TIMEOUT}
    )
  }

  useEffect( () => { 
    healthCheck()
    if( typeof(getDataFromLocalStorage("isLoggedIn")) === "object" ) { setIsLoggedIn(false) }
    else { setIsLoggedIn(true) }
  }, [])

  return (
    <div className="bg-black min-h-screen h-fit flex flex-col justify-center w-screen min-w-fit py-10 md:p-12 lg:px-52 overflow-hidden">
      <div className="sm:mt-2 xl:mt-4 sm:h-[80px]" style={{"textAlign": "center"}}>
        <img
          className="h-20 lg:h-28 xl:h-[7.5rem] 2xl:h-[8.25rem] mx-auto"
          src={Logo}
          alt=""
        />
      </div>
      <div className="hidden sm:flex mx-auto overflow-x-hidden md:mx-5 mt-10 2xl:mt-14 sm:justify-evenly [&>*]:hover:cursor-pointer  md:overflow-hidden  w-[calc(100w-4px)] h-fit md:w-auto">
        {
          isNotAvailable === false && loader ?
            introImgType.map( img => (
              <div className="p-4 relative">
                <img
                  onMouseOver={e=> (e.currentTarget.src=img.over)}
                  onMouseLeave={e => (e.currentTarget.src=img.leave)}
                  onClick={() => navigate(img.navigate)}
                  className={`h-72 xl:h-[22rem] 2xl:h-[26rem] min-w-[190px] mx-auto`}
                  src={img.leave}
                  alt=""
                />
              </div>   
            ))
          : isNotAvailable && loader === false ? 
            <PageNotAvailable />
          : isNotAvailable === false && loader === false ?
              <div className="h-2 w-4 bg-slate-600 rounded mt-2">
              </div> 
          : <PageNotAvailable />
        }
      </div>
      <div className="mx-20 mt-10 flex flex-col sm:hidden gap-3">
        {
          isNotAvailable === false && loader ?
            introImgType.map( img => (
              <img
                onTouchStart={() => startPressTimer()}
                onTouchEnd={() => {
                  !isLongPress.current && navigate(img.navigate);
                  clearTimeout(timerRef.current);
                }}
                onClick={() => navigate(img.navigate)}
                src={img.mobile}
                alt=""
              />
            ))
          : isNotAvailable && loader === false ? 
            <PageNotAvailable />
          : isNotAvailable === false && loader === false ?
              <div className="h-2 w-4 bg-slate-600 rounded mt-2">
              </div> 
          : <PageNotAvailable />
        }
      </div>
      <p className="text-white text-center px-5 text-xs xl:text-sm mt-10">
        COPYRIGHT ©.
        <span onClick={() => clickCount()}>SAMTREE&nbsp;</span>
        ALL RIGHTS RESERVED
      </p>
    </div>
  );
};
