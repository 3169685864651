import { useState, useEffect } from "react";
import { SideBar } from "../Sidebar"
import clsx from "clsx";
import { Dropdown } from "../common/Dropdown";
import SearchIcon from "../../../../assets/icons/search.svg";
import RefreshIcon from "../../../../assets/icons/refresh.svg";
import moment from "moment";
import watchGray from "../../../../assets/icons/FloatingMenu/watchGray.png"
import watchWhite from "../../../../assets/icons/FloatingMenu/watchWhite.png"
import watchBlack from "../../../../assets/icons/FloatingMenu/watchBlack.png"
import classicGray from "../../../../assets/icons/FloatingMenu/classicGray.png"
import classicBlack from "../../../../assets/icons/FloatingMenu/classicBlack.png"
import ultraWhite from "../../../../assets/icons/FloatingMenu/ultraWhite.png"
import ultraBlack from "../../../../assets/icons/FloatingMenu/ultraBlack.png"
import ultraOrange from "../../../../assets/icons/FloatingMenu/ultraOrange.png"
import { refreshToken, validateToken } from "../../../App/utils/util";
import { apiCall, METHOD } from "../../../../service/baseApiCall";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { POST_FLOATING_MENU } from "../../../../service/apiEndPoints";
import { CreateWatchSkinModal } from "./floatingMenuModal";

interface ImageRegistry {
    icon: string
    preview: string
}

export const FloatingMenu = () => {
    const [collapsed, setCollapsed] = useState<boolean>(false);
    const [search, setSearch] = useState<string>("");
    const [limit, setLimit] = useState<number>(10);
    const [loader, setLoader] = useState<boolean>(true);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [page, setPage] = useState<number>(1);
    const [data, setData] = useState([])
    const [refreshed, setRefreshed] = useState(false);
    const [isReloginOpen, setReloginIsOpen] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const [registerModal, setRegisterModal] = useState<boolean>(false)
    const [imageRegistry, setImageRegistry] = useState<ImageRegistry[]>([])

    const navigate = useNavigate()

    const loadData = () => {
        if( validateToken() === false ){ navigate("/login"); return}
        setLoader(true);
        apiCall(
        POST_FLOATING_MENU,
        {},
        (res: any) => {
            if (res?.success) {
            setData(res?.data);
            setTotalCount(res?.pagination?.total);
            setTotalPages(res?.pagination?.total_page);
            setRefreshed(false);
            setLoader(false);
            } else {
                if (res?.data?.message === "record not found") {
                    setData([]);
                    setTotalCount(0);
                    setTotalPages(0);
                    setRefreshed(false);
                    setLoader(false);
                }
            }
        },
        async (err: any) => {
            if (err === "unauthorized" && !refreshed) {
            const check = await refreshToken();
            if (check) {
                setRefreshed(true);
                loadData();
            } else setReloginIsOpen(true);
            } else if (err === "unauthorized") {
            setReloginIsOpen(true);
            } else {
            console.log(err);
            setLoader(false);
            toast.error("something went wrong!");
            }
        },
        METHOD.GET,
        {}
        );
    }
    const FloatingWatchFrames = ( props: any ) => {
    let myImages = [ {raw: watchGray,   name: "watchGray"}, 
                     {raw: watchWhite,  name: "watchWhite"},
                     {raw: watchBlack,  name: "watchBlack"},
                     {raw: ultraWhite,  name: "ultraWhite"},
                     {raw: ultraOrange,  name: "ultraOrange"},
                     {raw: ultraBlack,  name: "ultraBlack"},
                     {raw: classicGray, name: "classicGray"}, 
                     {raw: classicBlack,name: "classicBlack"}
                   ]
    return (
        <div className="floating-menu-inside">
            {
                myImages.map( (image,idx) => (
                    <img 
                        key={idx}
                        src={image.raw}
                        alt=""
                    />
                ))
            }
        </div>
    )
}
    return (
        // 제일 위 쪽에 samtree 프리뷰 이미지 넣기, 프리뷰 이미지 내 아이콘의 width, height를 코드에 박아두는게 아닌 
        // db에서 관리하게 하기. 관리 페이지에서 설정가능 하게 끔 수정 
        <div className="flex w-full">
            <SideBar 
                collapsed={collapsed}
                setCollapsed={setCollapsed}
                fromCoupon = { false }
            />

            {/* body start */}
            <div
                className={clsx(
                "mainClass relative flex flex-col justify-center overflow-auto",
                collapsed
                    ? "ml-12 lg:ml-14 xl:ml-14 w-full"
                    : "ml-12 w-full md:ml-[13em]"
                )}
            >
                <div className="flex w-full flex-row justify-between">
                    <span className="text-3xl font-bold p-4">워치스킨</span>
                    <button
                        onClick={ () => setRegisterModal(true) }
                        className="text-white bg-sky-500 hover:bg-sky-600 font-medium rounded-lg text-sm m-4 mt-5 px-2 py-2.5 w-24"
                    >
                        Register
                    </button>
                </div>

                <div className="flex flex-row justify-between align-middle mt-14 ml-5 mr-3 md:ml-10 md:mr-5">
                <div
                    className="flex flex-col md:flex-row justify-between w-full"
                >
                    <div className="flex flex-col lg:flex-row ">
                    <div 
                        className={"floating-menu-open"}
                        // TODO - DB에서 데이터 가져오게 수정
                        style={{ position: "static" }}
                    > 
                        <FloatingWatchFrames />
                    </div>
                    </div>
                    <div className="[&>*]:mr-2 [&>*]:hover:cursor-pointer mr-0 mt-8 flex flex-row h-10">
                    <button>
                        <img src={SearchIcon} alt="" />
                    </button>
                    <img src={RefreshIcon} alt="" />
                    </div>
                </div>
                </div>
                <div className="divide-y mt-5">
                    <div />
                    <div />
                </div>

                <div className="ml-5 mr-3 md:ml-10 md:mr-5 mt-5 flex flex-row justify-between">
                    <div className=" flex flex-row">
                        <span className="font-bold">Items:</span>
                        <span className="text-[#697274] ml-2 animate-pulse">
                        {loader ? (
                            <div className="h-2 w-4 bg-slate-600 rounded mt-2" />
                        ) : (
                            totalCount
                        )}
                        </span>
                    </div>
                    <Dropdown
                        className="-mt-4 -mb-2 mr-2"
                        for="search"
                        onChange={(e: number) => setLimit(e)}
                        items={[
                            { key: "10", value: 10 },
                            { key: "15", value: 15 },
                            { key: "20", value: 20 },
                            { key: "25", value: 25 },
                            { key: "50", value: 50 },
                            { key: "100", value: 100 },
                        ]}
                    />
                </div>
                <div className="ml-5 mr-3 md:ml-10 md:mr-7 mt-5 overflow-auto">
                <table className="w-full [&>*]:border">
                    <thead className="">
                    <tr className="bg-[#EFF7FD] [&>*]:p-5 [&>*]:border">
                        <th>No</th>
                        <th>아이콘</th>
                        <th>프리뷰 이미지</th>
                        <th>등록날짜</th>
                        <th>관리</th>
                    </tr>
                    </thead>
                    <tbody className="[&>*]:border">
                    {loader ? (
                        <tr className="[&>*]:border-r-[1px] animate-pulse">
                            <td>
                                <div className="h-2 bg-slate-700 rounded" />
                            </td>
                            <td>
                                <div className="h-2 bg-slate-700 rounded" />
                            </td>
                            <td>
                                <div className="h-2 bg-slate-700 rounded" />
                            </td>
                            <td>
                                <div className="h-2 bg-slate-700 rounded" />
                            </td>
                            <td>
                                <div className="h-2 bg-slate-700 rounded" />
                            </td>
                        </tr>
                    ) : !data.length ? (
                        <tr className="[&>*]:border-r-[1px]">
                        <td colSpan={9} className="font-bold text-gray-700">
                            No Data to Show
                        </td>
                        </tr>
                    ) : (
                        data.map((e: any, i) => (
                        <tr className="[&>*]:border-r-[1px]">
                            <td>{ e?.no }</td>
                            <td>{e?.icon_url}</td>
                            <td>{e?.preview_url}</td>
                            <td>
                                {moment(e?.created_at).format("YYYY/MM/DD HH:mm:ss")}
                            </td>
                            <td></td>
                            
                        </tr>
                        ))
                    )}
                    </tbody>
                </table>
                </div>
            </div>
            {/* end of body */}
            <CreateWatchSkinModal 
                setOpen={ setRegisterModal }
                isOpen={ registerModal }
            />

        </div>
    )
}
