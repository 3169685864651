import { Transition, Dialog } from "@headlessui/react"
import { Button } from "@mui/material"
import { Fragment, useEffect, useState } from "react"
import { X } from "react-feather"
import { WatchSkinImageUpload } from "./imageUpload"
import { apiCall, METHOD } from "../../../../service/baseApiCall"
import { POST_FLOATING_MENU } from "../../../../service/apiEndPoints"

export const CreateWatchSkinModal = (props: any) => {

    const [previewImage, setPreviewImage] = useState<any>([])
    const [iconImage, setIconImage] = useState<any>([])
    const [submitDisabled, setSubmitDisabled] = useState<boolean>(true)

    const setPImage = (e: any) => { setPreviewImage(e) }
    const setIImage = (e: any) => { setIconImage(e) }

    let images = [
        { "image": iconImage, "setImage": setIImage, "placeholder": "아이콘 이미지", "dragMessage": "여기에 아이콘 이미지 드랍하기"  },
        { "image": previewImage, "setImage": setPImage, "placeholder": "프리뷰 이미지", "dragMessage": "여기에 프리뷰 이미지 드랍하기"},
    ]

    const init = () => { setPImage([]); setIImage([]) }
    useEffect(() => {
        if(!props.isOpen) {
            init()
        }
    }, [props.isOpen])

    useEffect(() => {
        let icoFlag = false
        ,   prvFlag = false

        images.map( (img, idx) => {
            switch(idx) {
                case 0: 
                    if(img.image.length > 0) icoFlag = true
                    break
                case 1:
                    if(img.image.length > 0) prvFlag = true
                    break
            }
        })
        if(icoFlag && prvFlag) setSubmitDisabled(false)
        else setSubmitDisabled(true)
    }, [previewImage, iconImage])

        console.log(iconImage[0], previewImage[0])

    const postImage = () => {
        setSubmitDisabled(true)
        let formData = new FormData()
        formData.append("floating_menu_icon", iconImage[0].file)
        formData.append("floating_menu_preview", previewImage[0].file)
        apiCall(
            POST_FLOATING_MENU,
            formData,
            (res: any) => {
                console.log(res)
                init()
            },
            (err: any) => {
                console.log(err)
                init()
            },
            METHOD.POST,
            {}
        )
    }

    return (
        <Transition appear show={props.isOpen} as={Fragment} >
            <Dialog as="div" className="relative z-10" onClose={() => props.setOpen(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>
                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="min-w-[450px] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                <Dialog.Title
                                    as="h3"
                                    className="text-xl font-medium leading-6 text-gray-900"
                                >
                                    <div style={{ alignItems: "center", justifyContent: "space-between"}} className="flex">
                                        <div>
                                            워치 스킨 등록 
                                        </div>
                                        <div
                                            style={{ cursor: "pointer"}}
                                            onClick={() => props.setOpen(false)}
                                        >
                                            <X className="text-white sm:h-8 sm:w-8 bg-[#d23434] rounded shadow-md hover:cursor-pointer" />
                                        </div>
                                    </div>
                                </Dialog.Title>
                                <div className="mt-4">
                                    <WatchSkinImageUpload 
                                        images={ images }
                                    />
                                </div>
                                <div className="mt-4 flex" style={{ gap:"1rem", justifyContent: "end"}}>
                                    <Button
                                        style={{width: "150px"}}
                                        variant="contained"
                                        disabled={submitDisabled}
                                        onClick={() => { postImage() } }
                                    >
                                        저장
                                    </Button>
                                    <Button 
                                        style={{width: "150px"}}
                                        variant="contained"
                                        color="error"
                                        onClick={ () => props.setOpen(false) }
                                    >
                                        취소
                                    </Button>
                                    
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>

            </Dialog>
        </Transition>
    )
}