/** 
 * 최초 LoadMore의 프로그레스바 관리를 위해 전역 state를 사용
 * 추후 필요할 경우 더 늘려가는 방향으로 수정해야함
 */
import { create } from "zustand";

interface LoadMoreStateImpl {
    showProgressBar: boolean;
    setProgressBar: (show: boolean) => void;
}

export const useLoadState = create<LoadMoreStateImpl>((set) => ({
    showProgressBar: false,
    setProgressBar: (show) => set(() => ({ showProgressBar: show })),
}))