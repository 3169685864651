interface LoadMoreProps {
  onClick: any,
  disable: boolean
}

export const LoadMore = (props: LoadMoreProps) => {
  return (
    <div id="loadMore" className="flex pb-5 justify-center h-[90px] m-[5rem] ">
      <button
        style={{ 
          cursor: props.disable ? "not-allowed": "inherit" 
        }}
        disabled={props.disable}
        onClick={() => props.onClick()}
        className="max-w-fit bg-gray-700 hover:brightness-110 px-14 py-3 font-semibold text-white rounded-lg whitespace-nowrap"
      >
        Load More
      </button>
    </div>
  );
};
