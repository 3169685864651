import { useState, useEffect } from "react"
import { useNavigate, useLocation } from "react-router-dom";
import clsx from "clsx";
import SamWatchIcon from "../../../../assets/icons/watch_icon_1.png"
import SamThemeIcon from "../../../../assets/icons/samtheme-ico-rev.png"
import SamStudioIcon from "../../../../assets/icons/studio_icon_1.png"
import YamWatchIcon from "../../../../assets/icons/yam_icon.png"
import CouponRegister from "../../../../assets/icons/coupon-ico.png"
import BannerRegister from "../../../../assets/icons/banner-ico.png"

export const NavigationButtons = () => { 

    const [name, setName] = useState("samwatch");
    const navigate = useNavigate();
    const location = useLocation()
    useEffect( () => {
    for (const route of location.pathname.split("/")) {
        switch( route ) {
            case "samwatch"   : setName("samwatch")  ; break
            case "yamwatch"   : setName("yamwatch")  ; break
            case "samtheme"   : setName("samtheme")  ; break
            case "samstudio"  : setName("samstudio") ; break
            case "coupon"     : setName("coupon")    ; break
            case "banner"     : setName("banner")    ; break
            case "floating-menu" : setName("floating-menu"); break
        }
        } 
    }, [location])

    return (
        <div className="control-group flex flex-col items-center">
            <button 
                className={clsx(
                    "m-1.5 p-1.5 md:p-2 md:m-2 justify-center rounded-xl hover:shadow-md focus:bg-sky-500 ",
                    name === "samwatch" ? "bg-sky-500" : "hover:bg-[#262E43]"
                )}
                onClick={ () => { navigate("/dashboard/samwatch")} }
            >
                <img style={{ width: "30px", height: "20px"}} src={SamWatchIcon} alt="" />
            </button>

            <button 
                className={clsx(
                    "m-1.5 p-1.5 md:p-2 md:m-2 justify-center rounded-xl hover:shadow-md focus:bg-sky-500 ",
                    name === "yamwatch" ? "bg-sky-500" : "hover:bg-[#262E43]"
                )}
                onClick={ () => { navigate("/dashboard/yamwatch")} }
            >
                <img style={{ width: "30px", height: "20px"}} src={YamWatchIcon} alt="" />
            </button>

            <button 
                className={clsx(
                    "m-1.5 p-1.5 md:p-2 md:m-2 justify-center rounded-xl hover:shadow-md focus:bg-sky-500 ",
                    name === "samtheme" ? "bg-sky-500" : "hover:bg-[#262E43]"
                )}
                onClick={ () => { navigate("/dashboard/samtheme"); }}
            >
                <img style={{ width: "30px", height: "20px"}} src={SamThemeIcon} alt="" />
            </button>

            <button 
                className={clsx(
                    "m-1.5 p-1.5 md:p-2 md:m-2 justify-center rounded-xl hover:shadow-md focus:bg-sky-500 ",
                    name === "samstudio" ? "bg-sky-500" : "hover:bg-[#262E43]"
                )}
                onClick={ () => { navigate("/dashboard/samstudio");  } }
            >
                <img style={{ width: "30px", height: "20px"}} src={SamStudioIcon} alt="" />
            </button>

            {/* 워치와 기타 기능과의 경계선 */}
            <div className="menu-icon-border" />

            {/* 쿠폰 관리 */}
            <button 
                className={clsx(
                    "m-1.5 p-1.5 md:p-2 md:m-2 justify-center rounded-xl hover:shadow-md focus:bg-sky-500 ",
                    name === "coupon" ? "bg-sky-500" : "hover:bg-[#262E43]"
                )}
                onClick={ () => { navigate("/dashboard/coupon");  } }
            >
                <img style={{ width: "30px", height: "20px"}} src={CouponRegister} alt="" />
            </button>

            {/* 배너 관리 */}
            <button
                className={clsx(
                    "m-1.5 p-1.5 md:p-2 md:m-2 justify-center rounded-xl hover:shadow-md focus:bg-sky-500 ",
                    name === "banner" ? "bg-sky-500" : "hover:bg-[#262E43]"
                )}
                onClick={ () => { navigate("/dashboard/banner");  } }
            >
                <img style={{ width: "30px", height: "20px"}} src={BannerRegister} alt="" />
            </button>

            <button
                className={clsx(
                    "m-1.5 p-1.5 md:p-2 md:m-2 justify-center rounded-xl hover:shadow-md focus:bg-sky-500 ",
                    name === "banner" ? "bg-sky-500" : "hover:bg-[#262E43]"
                )}
                onClick={ () => { navigate("/dashboard/floating-menu");  } }
            >
                <img style={{ width: "30px", height: "20px"}} src={BannerRegister} alt="" />
            </button>
        </div>
    )
}