import { useState } from "react"
import ReactImageUploading from "react-images-uploading"
import { toast } from "react-toastify"
import { png2jpg } from "../../../App/utils/util"
import { X } from "react-feather"


export const WatchSkinImageUpload = (props: any) => {

    const onChange = async( image: any, idx: number ) => {
        let jpg = await png2jpg(image[0].file)
        props.images[idx].setImage([jpg])
    }

    const onError = () => {
        { toast.error("jpg, png 이미지만 업로드해주세요.") }
        return
    }

    const cancelHandler = (idx: number) => { props.images[idx].setImage([]) }

    return (
        <div className="flex gap-4 justify-center">
            { props.images.map((target: any, idx: number) => (
                <ReactImageUploading
                    key={idx}
                    multiple={false}
                    value={ target.image }
                    onChange={ (imageList) => onChange(imageList, idx) }
                    acceptType={["jpg", "png"]}
                    maxNumber={2}
                    onError={ onError }
                >
                    {
                        ({ 
                            imageList,
                            onImageUpload,
                            isDragging,
                            dragProps,
                        }) => {
                            return (
                                <>
                                    <div
                                        className="min-w-fit mx-1"
                                        style={{
                                            // display: "flex",
                                            // position: "relative",
                                        }}
                                    >
                                        {
                                            imageList.length ? 
                                            (
                                                <>
                                                    <div
                                                        className="h-40 w-[15rem] border text-center flex flex-col justify-center text-gray-400 hover:cursor-pointer relative"
                                                        {...dragProps}
                                                        key={idx}
                                                    >
                                                        <img 
                                                            src={imageList[0]?.data_url}
                                                            className="h-[10rem] rounded-lg"
                                                        />
                                                        <div className=" absolute right-1 top-1">
                                                            <X
                                                                className="text-sky-600 bg-white rounded shadow-md shadow-sky-500/50"
                                                                onClick={() =>{ cancelHandler(idx) } }
                                                            />
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="text-center">
                                                        { target.placeholder}
                                                    </div>
                                                </>
                                        
                                            )
                                            
                                            : (
                                                <>
                                                    <div
                                                        id={String(idx)}
                                                        {...dragProps}
                                                        className="h-40 w-[15rem] border text-center flex flex-col justify-center text-gray-400 hover:cursor-pointer"
                                                        onClick={ onImageUpload }
                                                    >
                                                        { isDragging ? target.dragMessage : "클릭 또는 드래그 앤 드롭" }
                                                    </div>
                                                    <div className="text-center" style={{ fontWeight: "bolder" }}>
                                                        {`${target.placeholder}`}
                                                    </div>
                                                </>
                                                
                                            )
                                        }
                                    </div>
                                </>
                            )
                        } 
                    }

                </ReactImageUploading>
            ))}
        </div>
    )
}